import {
  EMAIL,
  EMAIL_LINK,
  PHONE_LINK,
} from "../components/estates/details/fields/Ansprechpartner";

import { Container } from "react-bootstrap";
import Layout from "../components/layout/Layout";
import React from "react";

const ImprintPage = () => {
  return (
    <Layout>
      <Container>
        <h1 className="gold-header">Impressum</h1>
        <p>
          <h4 className="mt-4">Angaben gemäß § 5 TMG</h4>
          Detharding Immobilien GbR
          <br />
          An den Vogelwiesen 2 B<br />
          34132 Kassel
          <h4 className="mt-4">Gesetzlicher Vertreter:</h4>
          Philip Detharding
          <br />
          Christiane Detharding
          <h4 className="mt-4">Kontakt</h4>
          Tel.: 0561 59861851 {PHONE_LINK}
          <br />
          Fax: 0561 59861859
          <br />
          E-Mail: {EMAIL} {EMAIL_LINK}
          <h4 className="mt-4">Umsatzsteuer-ID</h4>
          Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:
          <br />
          DE338945059
          <h4 className="mt-4">Gewerbeerlaubnis & Aufsichtsbehörde:</h4>
          Gewerbeerlaubnis gem. § 34c GewO wurde erteilt durch die Stadt Kassel
          <br />
          Ordnungsamt der Stadt Kassel
          <br />
          Kurt-Schumacher-Str. 29, 34117 Kassel
          <br />
          E-Mail: ordnungsamt@kassel.de
          <h4 className="mt-4">EU-Streitschlichtung</h4>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
          .
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
          <h4 className="mt-4">
            Verbraucherstreitbeilegung/Universalschlichtungsstelle
          </h4>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </Container>
    </Layout>
  );
};

export default ImprintPage;
